<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card color="#174282">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Ateliers
            </div>
          </template>
          <v-card-text>
            <v-row>
              <v-col
                md="12"
                cols="12"
              >
<iframe src="https://onedrive.live.com/embed?cid=33668E9665609F80&amp;resid=33668E9665609F80%211018&amp;authkey=ALlvocK_Q9U74dA&amp;em=2&amp;wdAr=1.7777777777777777" width="100%" height="565px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
<iframe src="https://onedrive.live.com/embed?cid=33668E9665609F80&amp;resid=33668E9665609F80%211017&amp;authkey=AIE0ObEp79emBqU&amp;em=2&amp;wdAr=1.7777777777777777" width="100%" height="565px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
<iframe src="https://onedrive.live.com/embed?cid=33668E9665609F80&amp;resid=33668E9665609F80%211016&amp;authkey=AIDUsSHRRRi9HoU&amp;em=2&amp;wdAr=1.7777777777777777" width="100%" height="565px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
<iframe src="https://onedrive.live.com/embed?cid=33668E9665609F80&amp;resid=33668E9665609F80%211019&amp;authkey=ALdTUcx1LCo13eg&amp;em=2&amp;wdAr=1.7777777777777777" width="100%" height="565px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
<iframe src="https://onedrive.live.com/embed?cid=33668E9665609F80&amp;resid=33668E9665609F80%211013&amp;authkey=AKgN-PTj4pFIHts&amp;em=2&amp;wdAr=1.7777777777777777" width="100%" height="565px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
<iframe src="https://onedrive.live.com/embed?cid=33668E9665609F80&amp;resid=33668E9665609F80%211011&amp;authkey=ABVgNJEjlSelpKs&amp;em=2&amp;wdAr=1.7777777777777777" width="100%" height="565px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
<iframe src="https://onedrive.live.com/embed?cid=33668E9665609F80&amp;resid=33668E9665609F80%211012&amp;authkey=AKZtxJDclhMTua0&amp;em=2&amp;wdAr=1.7777777777777777" width="100%" height="565px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
<iframe src="https://onedrive.live.com/embed?cid=33668E9665609F80&amp;resid=33668E9665609F80%211014&amp;authkey=AO0V5gWx7-V1s_c&amp;em=2&amp;wdAr=1.7777777777777777" width="100%" height="565px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
<iframe src="https://onedrive.live.com/embed?cid=33668E9665609F80&amp;resid=33668E9665609F80%211015&amp;authkey=ABON2aI6kbzr4Y8&amp;em=2&amp;wdAr=1.7777777777777777" width="100%" height="565px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
<iframe src="https://onedrive.live.com/embed?cid=33668E9665609F80&amp;resid=33668E9665609F80%211038&amp;authkey=AJyq8nTygvariVg&amp;em=2&amp;wdAr=1.7777777777777777" width="100%" height="565px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
<iframe src="https://onedrive.live.com/embed?cid=33668E9665609F80&amp;resid=33668E9665609F80%211009&amp;authkey=AKCBSHxpuiUlrkU&amp;em=2&amp;wdAr=1.7777777777777777" width="100%" height="565px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
<iframe src="https://onedrive.live.com/embed?cid=33668E9665609F80&amp;resid=33668E9665609F80%211039&amp;authkey=AN25KbgKBRZL1Og&amp;em=2&amp;wdAr=1.7777777777777777" width="100%" height="565px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
<iframe src="https://onedrive.live.com/embed?cid=33668E9665609F80&amp;resid=33668E9665609F80%211042&amp;authkey=AKZ7gTQJOA_G6gg&amp;em=2&amp;wdAr=1.7777777777777777" width="100%" height="565px" frameborder="0">Ceci est un document <a target="_blank" href="https://office.com">Microsoft Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
          </v-col>
</v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="sass">
.WACStatusBarContainer
  display: none!important
</style>
<script>
  export default {
    name: 'Ateliers',
  }
</script>
